<template>
  <div class="text-nowrap">
    <b-row>
      <b-col><h6 v-text="item.name" /></b-col>
    </b-row>
    <b-form-row v-if="item.publicEmail" class="flex-nowrap pb-1">
      <b-col>
        <b-link :href="`mailto:`+item.publicEmail" v-text="item.publicEmail" />
      </b-col>
    </b-form-row>
    <b-form-row v-if="item.publicPhone" class="flex-nowrap">
      <b-col>
        <b-link :href="`tel:`+item.publicPhone" v-text="item.publicPhone" />
      </b-col>
    </b-form-row>
    <hr v-if="item.publicEmail || item.publicPhone" class="pb-1 mt-1">
    <b-form-row v-if="item.street || item.number" class="flex-nowrap">
      <b-col>
        <div>{{ item | formatStreet }}</div>
      </b-col>
    </b-form-row>
    <b-form-row v-if="item.city || item.zip" class="flex-nowrap">
      <b-col>
        <div>{{ item | formatCity }}</div>
      </b-col>
    </b-form-row>
    <hr v-if="btnText && (item.street || item.city)" class="pb-1 mt-1">
    <b-form-row v-if="btnText" class="flex-nowrap text-center">
      <b-col>
        <b-button variant="primary" size="sm" @click="$emit('clicked',index)">{{ btnText }}</b-button>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { vm } from '@/main';

export default {
  filters: {
    formatStreet(item) {
      if (vm.$store.state.oUser.formatId == 1) { // store not accessible, as filters are pure JS
        return item.street + ' ' + item.number;
      } else {
        return item.number + ' ' + item.street;
      }
    },
    formatCity(item) {
      if (vm.$store.state.oUser.formatId == 1) { // store not accessible, as filters are pure JS
        return item.zip + ' ' + item.city;
      } else {
        return item.city + ' ' + item.zip;
      }
    },
    // formatCountry(item) {
    //   if (vm.$store.state.oUser.formatId == 1) { // store not accessible, as filters are pure JS
    //     return item.countryId;
    //   } else {
    //     return item.stateId + item.countryId;
    //   }
    // }
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    btnText: {
      type: String,
      required: true,
    },
  }
}
</script>

<style scope>
hr {
  margin-bottom: 6px;
  margin-top: 4px;
  border-width: 2px;
}
</style>