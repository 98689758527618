<template>
  <b-form-datepicker :id="id" :state="state" :required="required" :form="formid"
      :value="value" @input="updateVal($event)" @shown="updateMin()"
      :locale="locale" :min="start" :max="getMaxYear" hide-header :offset="offset"
      :start-weekday="getSWD" :direction="getDir" :dateFormatOptions="format"
      :label-prev-year="$t('dt.py')" :label-prev-month="$t('dt.pm')" :label-current-month="$t('dt.cm')" :label-next-month="$t('dt.nm')"
      :label-next-year="$t('dt.ny')" :label-today="$t('dt.td')" :label-selected="$t('dt.s')" :label-no-date-selected="$t('dt.ns')"
      :label-calendar="$t('dt.c')" :label-nav="$t('dt.cn')" :label-help="$t('dt.he')" />
</template>

<script>
import { utcToZonedTime } from 'date-fns-tz';

export default {
  data() {
    return {
      start: null,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    getMaxYear() {
      let d = utcToZonedTime(Date.now(), this.$store.state.settings.timezone);
      return new Date(d.getFullYear() + this.maxYearOffset, d.getMonth(), d.getDate());
    },
    getSWD() {
      return this.$i18n.getDateTimeFormat(this.$i18n.locale).swd;
    },
    getDir() {
      return this.$i18n.getDateTimeFormat(this.$i18n.locale).dir;
    }
  },
  methods: {
    updateVal(v) {
      this.$emit('input', v);
    },
    updateMin() {
      this.start = utcToZonedTime(Date.now(), this.$store.state.settings.timezone);
    },
  },
  props: {
    maxYearOffset: {
      type: Number,
      default: 1
    },
    state: {
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null,
    },
    offset: {
      type: String,
      required: true,
    },
    formid: {
      type: String,
      required: true,
    },
    format: {
      type: Object,
      default() {
        return { year: 'numeric', month: '2-digit', day: '2-digit' };
      }
    }
  }
}
</script>
