<template>
  <div v-if="isUser" :key="render">
    <b-row v-if="!f.customerUuid4" class="justify-content-center">
      <b-col cols="10">
        <Search id="resSearch" class="mt-1" @warn="onWarn" @action="onReserve" :labelText="$t('res.search')" />
      </b-col>
    </b-row>

    <b-form class="pt-2" v-if="f.customerUuid4" id="resNew" @submit.stop.prevent>
      <b-form-row>
        <b-col cols="12" sm="6">
          <b-form-group :label="$t('res.fre')" label-for="resNewInputName" label-class="text-nowrap" label-cols="4">
            <b-input type="text" plaintext class="font-weight-bold w-100 text-truncate" id="resNewInputName" v-model="name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group :label="$t('res.fctl')" label-for="resNewInputCity" label-class="pl-sm-4" label-cols="4">
            <b-input type="text" plaintext class="font-weight-bold border-0" id="resNewInputCity" v-model="city" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" sm="6">
          <b-form-group :label="$t('res.fda')" label-for="resNewInputDate" label-class="text-nowrap" label-cols="4">
            <DatePicker id="resNewInputDate" formid="resNew" v-model="date" :state="valDates" required 
                :maxYearOffset="2" :format="getDateFormat" :offset="isXs?`-100`:`0`" />
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group :label="$t('res.fti')" label-for="resNewInputTime" label-class="text-nowrap pl-sm-4" label-cols="4">
            <TimePicker id="resNewInputTime" v-model="time" :state="valDates" required :format="getTimeFormat" formid="resNew" />
            <b-form-valid-feedback>
              <p v-if="isMidNight" class="mb-1">
                {{ $t('res.fmw') }}
              </p>
            </b-form-valid-feedback>
            <b-form-invalid-feedback>
              <p v-if="isInPast" class="mb-1">
                {{ $t('res.flw') }}
              </p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" sm="6" order="2" order-sm="1">
          <b-form-group :label="$t('res.ffo')" label-for="resNewInputSize" label-cols="4">
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  <b-icon-people />
                </b-input-group-text>
              </template>
              <b-input type="number" number id="resNewInputSize" v-model="f.seats" :min="1" :max="20" :state="valSeats" required />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6" order="1" order-sm="2">
          <b-form-group :label="$t('res.fdu')" label-for="resNewInputDur" label-class="pl-sm-4" label-cols="4">
            <b-input-group :prepend="$t('dt.tms')">
              <b-input type="number" number id="resNewInputDur" v-model="f.duration" :min="15" :max="1440" :step="15" :state="valDur" required />
            </b-input-group>
            <b-form-invalid-feedback :state="valDur">
              <p class="mb-1">
                {{ $t('res.fxw') }}
              </p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <template v-if="f.allowedAmount!==0">
        <b-form-row>
          <b-col cols="12" sm="6">
            <b-form-group :label="$t('res.fdl')" label-for="resNewInputAm" label-cols="4">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text>
                    <b-icon-credit-card />
                  </b-input-group-text>
                </template>
                <b-input type="text" disabled id="resNewInputAm" :value="getMoney(f.allowedAmount,f.allowedCurrency)" :state="true" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group :label="$t('res.fbl')" label-for="resNewBal" label-class="text-nowrap pl-sm-4" label-cols="4">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text>
                    <b-icon-credit-card />
                  </b-input-group-text>
                </template>
                <b-input type="text" disabled id="resNewBal" :value="getMoney(acc.balAmount,acc.balCurrency)" :state="valBalance" />
                <template v-if="!(acc.showBtn===false&&acc.balAmount>=f.allowedAmount)" #append>
                  <b-button v-if="acc.showBtn===true" to="/transactions/accounts_current" variant="secondary"><b-icon-plus-square /> {{ $t('res.fbb') }}</b-button>
                  <b-button v-if="acc.showBtn===false&&acc.balAmount<f.allowedAmount" @click="onChaExecution" variant="secondary"><b-icon-credit-card /> {{ $t('res.fbc') }}</b-button>
                </template>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="acc.showBtn===false&&acc.balAmount>=f.allowedAmount">
          <b-col cols="12">
            <b-form-group :label="$t('res.fcl')" label-for="resNewInputCnf" label-cols="4" label-cols-sm="2" class="align-items-baseline">
              <b-form-checkbox id="resNewInputCnf" v-model="f.confirmDeposit" :value="true" :uncheckedValue="false" :state="valCnf" required>
                {{ $t('res.fct') }}
              </b-form-checkbox>
              <b-form-invalid-feedback :state="valCnf">
                <i18n path="res.fcw" tag="p" class="mb-1">
                  <template #link>
                    <b-link :to="{name: 'AnuTos'}" target="_blank" class="text-primary">{{ $t('res.fcwl') }}</b-link>
                  </template>
                </i18n>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group :label="$t('res.fwl')" label-for="resNewInputWai" label-cols="4" label-cols-sm="2" class="align-items-baseline">
              <b-form-checkbox id="resNewInputWai" v-model="f.confirmWaiveCnlRight" :value="true" :uncheckedValue="false" :state="valWai" required>
                {{ $t('res.fwt') }}
              </b-form-checkbox>
              <b-form-invalid-feedback :state="valWai">
                <p class="mb-1">
                  {{ $t('res.fww') }}
                </p>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
      </template>
      <b-form-row>
        <b-col cols="12">
          <b-form-group :label="$t('res.fmel')" label-for="resNewInputMsg" label-cols="4" label-cols-sm="2">
            <b-textarea id="resNewInputMsg" rows="3" v-model="f.userMessage" :maxlength="200" :placeHolder="$t('res.fcot')" :state="valMessage" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="float-right">
        <b-col cols="12">
          <b-button type="reset" variant="primary" class="mr-2 mr-sm-3" @click="onCancel">
            <Icon type="r" /> {{ $t('btn.r') }}
          </b-button>
          <b-button type="submit" variant="secondary" :disabled="val" @click="onSubmit">
            <Icon type="res" /> {{ $t('btn.res') }}
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
    <ModalCharge v-if="isUser" id="chaCreate" :chaUuid="acc.uuid4" :chaCurrency="acc.balCurrency" />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { BIconPeople, BIconCreditCard, BIconPlusSquare } from 'bootstrap-vue';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { addMinutes } from 'date-fns';
import { REST } from '@/components/RestCall';
import Search from '@/components/shared/Search';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';

export default {
  data() {
    return {
      f: {
        userUuid4: null,
        customerUuid4: null,
        startAt: null,
        duration: 60,
        seats: 2,
        userMessage: null,
        recurrence: null,
        count: null,
        allowedCurrency: null,
        allowedAmount: null,
        confirmDeposit: false,
        confirmWaiveCnlRight: false,
      },
      render: 0,
      date: null,
      time: null,
      name: '',
      city: '',
      acc: {
        uuid4: null,
        balAmount: null,
        balCurrency: null,
        showBtn: null,
        showCha: false,
      },
      mn: false,
      inpast: false,
    }
  },
  created() {
    this.fetchData();
  },
  computed: {
    isXs() {
      return this.$store.getters.isXs;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    valSeats() {
      if (this.f.seats == null) return null;
      return (typeof this.f.seats === 'number') && this.f.seats > 0 && this.f.seats <= 11;
    },
    valMessage() {
      if (!this.f.userMessage) return null;
      return this.f.userMessage.length < 200;
    },
    valDates() {
      if (this.date == null || this.time == null || this.date == '' || this.time == '') return null;
      if (this.f.startAt == null || this.f.duration == null) return false;
      return true;
    },
    valDur() {
      if (this.date == null || this.time == null) return null;
      return !(isNaN(this.f.duration) || this.f.duration <= 0 || this.f.duration % 15 != 0 || this.f.duration > 1440);
    },
    valCnf() {
      return this.f.allowedAmount === 0 ? true : (this.f.confirmDeposit);
    },
    valWai() {
      return this.f.allowedAmount === 0 ? true : (this.f.confirmWaiveCnlRight);
    },
    valBalance() {
      return this.f.allowedAmount === 0 ? true : (this.acc.balAmount >= this.f.allowedAmount && this.acc.balCurrency === this.f.allowedCurrency);
    },
    val() {
      return !(this.valDates && this.valDur && this.valSeats && this.valCnf && this.valWai && this.valBalance &&
              (this.f.userMessage == null || this.f.userMessage == '' || this.valMessage));
    },
    getDateFormat() {
      return this.$i18n.getDateTimeFormat(this.$i18n.locale).ds;
    },
    getTimeFormat() {
      return this.$i18n.getDateTimeFormat(this.$i18n.locale).t;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
    duration() {
      return this.f.duration;
    },
    isMidNight() {
      return this.mn === true;
    },
    isInPast() {
      return this.inpast;
    },
  },
  methods: {
    getMoney(a, c) {
      if (a == null) return '';
      return this.$n(a/100, { key: 'c', currency: c });
    },
    onWarn(text) {
      this.$store.commit('showWarn',text);
    },
    onReserve(custObj) {
      // careful here! custObj is now completely non-reactive! but it should not be necessary
      custObj = { customerUuid4: custObj.customerUuid4, customerName: custObj.name, customerCity: custObj.city, locationCurrency: custObj.locationCurrency, resAmount: custObj.resAmount };
      this.$store.commit('setNewRes', custObj);
      this.fetchData();
    },
    onCancel() {
      this.f.userUuid4 = null;
      this.f.customerUuid4 = null;
      this.f.seats = 2;
      this.f.userMessage = null;
      this.f.duration = 60;
      this.name = null;
      this.city = null;
      this.time = null;
      this.date = null;
      this.mn = false;
      this.inpast = false;
      this.render += 1;
      this.acc.showBtn = null;
      this.acc.showCha = null;
      this.acc.uuid4 = null;
      this.acc.balAmount = null;
      this.acc.balCurrency = null;
    },
    calcVals() {
      if (this.time == null || this.date == null) {
        this.f.startAt = null;
        return;
      }
      if (this.f.duration == null) return;
      let s = zonedTimeToUtc(this.date + ' ' + this.time, this.tz); // stored TZ
      let e = addMinutes(s, this.f.duration);

      this.mn = false;
      if (!isNaN(s.getTime()) && !isNaN(e.getTime())) {
        this.mn = this.checkMidNight(s, e) === true;
        this.f.startAt = s.toISOString().substring(0,16).replace('T',' ');
        this.inpast = s < Date.now();
        if (this.inpast || s.getUTCMinutes() % 15 != 0 ) {
          this.f.startAt = null;
        }
      }
    },
    checkMidNight(a, b) {
      if (a == null || b == null) return null;
      return (utcToZonedTime(a, this.tz).getDate() !== utcToZonedTime(b, this.tz).getDate()); // could exclude finishing at midnight
    },
    onSubmit() {
      if (this.f.userMessage == '') this.f.userMessage = null;
      REST.post('/reservations', this.f
        ).then(resp => {
          this.$store.commit('showSuccess',resp.d.message);
          this.onCancel(); // clear local data
          this.$store.commit('clearNewRes'); // clear central data
          this.$router.push('/reservations/'+resp.h['x-uuid']).catch(() => {}); // fwd
        }).catch(e => {
          this.$store.commit('showWarn',e.message);
        })
    },
    fetchData() {
      this.f.userUuid4 = this.$store.getters.uuid;
      this.f.customerUuid4 = this.$store.state.oResNew.customerUuid4;
      this.f.allowedCurrency = this.$store.state.oResNew.locationCurrency;
      this.f.allowedAmount = this.$store.state.oResNew.resAmount;
      this.name = this.$store.state.oResNew.customerName;
      this.city = this.$store.state.oResNew.customerCity;
      this.getAccData();
    },
    getAccData() {
      REST.get((this.isUser ? '/users/' : '/customers/') + this.$store.state.oLogin.uuid + '/accounts'
        ).then(resp => {
          // should search in returned list, but only one allowed at the moment
          this.acc.showBtn = false;
          this.acc.uuid4 = resp.d.data[0].uuid4;
          this.acc.balAmount = resp.d.data[0].amount;
          this.acc.balCurrency = resp.d.data[0].currency;
        }).catch(e => {
          if (e.code == 404) {
            this.acc.showBtn = true;
            this.acc.balAmount = null;
          } else {
            this.$store.commit('showWarn',e.message);
          }
        })
    },
    onChaExecution() {
      this.$nextTick(() => {
        this.$bvModal.show('chaCreate');
      })
    },
  },
  watch: {
    date() {
      this.calcVals();
    },
    time() {
      this.calcVals();
    },
    duration() {
      this.calcVals();
    },
    tz() {
      this.calcVals(); // update on TZ change
    },
  },
  components: {
    Search,
    DatePicker,
    TimePicker,
    Icon,
    BIconPeople,
    BIconCreditCard,
    BIconPlusSquare,
    ModalCharge: () => import('@/components/transactions/ModalCharge'),
  },
}
</script>

<style scoped>
/* .btnborder { border-color:#ccc; } */
</style>