<template>
  <b-form class="text-center mb-3" @submit.stop.prevent>
    <b-form-row class="mx-0">
      <label for="searchTextRest">{{ labelText }}</label>
    </b-form-row>
    <b-form-row class="mx-0">
      <b-input-group id="searchInput">
        <b-form-input type="text" id="searchTextRest" :placeHolder="$t('tab.n')" v-model="iname" autofocus @keyup.enter.native="onSearch" />
        <b-form-input type="text" id="searchTextCity" :placeHolder="$t('tab.c')" v-model="icity" @keyup.enter.native="onSearch" />

        <template #append>
          <b-dropdown variant="primary" id="searchDd" ref="searchGeoDd" right lazy menu-class="pb-0"
              v-if="geoLocationAvailable && !geo.err" @show="mapSearch" @hide="mapClose" no-caret>
            <template #button-content>
              <Icon type="map" hasNoText />
            </template>
            <div v-if="geo.gettingLocation" class="text-center px-2 px-sm-3 mapwidth">
              <b-spinner variant="primary" />
            </div>
            <b-container v-if="!geo.gettingLocation" class="px-2 px-sm-3 mapwidth">
              <b-form-group id="searchMapContent" :label="mapText" label-for="searchMap">
                <MapCore :center="geoCenter" :items="items" :btnText="$t('btn.res')" @clicked="onMapSelect" @refreshed="onMapRefresh" />
              </b-form-group>
            </b-container>
          </b-dropdown>
          <b-dropdown variant="primary" ref="btn" @show="onSearch" @hide="onHide" no-caret right lazy>
            <template #button-content>
              <Icon type="sea" /> {{ $t('btn.sea') }}
            </template>
            <b-table striped hover class="text-left px-2 px-sm-3 mb-0 overflow-x-hidden mapwidth" :fixed="isXs" table-class="mr-1" 
                :items="items" :fields="fields" sticky-header="300px">
              <template #cell(uuid4)="data">
                <b-button type="button" :variant="isXs?`secondary`:`primary`" :id="items.indexOf(data.item)" @click="onSelect(data.item, true)">
                  <span v-if="isXs" class="text-nowrap"><Icon type="add" hasNoText /></span>
                  <span v-else class="text-nowrap"><Icon type="res" /> {{ $t('btn.res') }}</span>
                </b-button>
              </template>
            </b-table>
          </b-dropdown>
        </template>
      </b-input-group>
    </b-form-row>
  </b-form>
</template>

<script>
// autofocus on searchText may break screenreader
import Icon from '@/components/Icon';
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';
import MapCore from '@/components/MapCore';

export default {
  data() {
    return {
      name: null,
      city: null,
      geo: {
        center: null,
        gettingLocation: false,
        err: false
      },
      items: [],
    }
  },
  computed: {
    fields() {
      return [
        { key: 'name', label: this.$t('tab.l'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 60%;', sortable: true },
        { key: 'city', label: this.$t('tab.c'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 40%;', sortable: true },
        { key: 'uuid4', label: (this.isXs?'':this.$t('tab.a')), class: 'px-1', thStyle: 'width: 40px;z-index:1000', sortable: false }
      ];
    },
    iname: {
      get() {
        return this.name;
      },
      set(v) {
        if (v == null || v == '') {
          this.name = null;
        } else {
          this.name = v;
        }
      }
    },
    icity: {
      get() {
        return this.city;
      },
      set(v) {
        if (v == null || v == '') {
          this.city = null;
        } else {
          this.city = v;
        }
      }
    },
    geoLocationAvailable() {
      return ('geolocation' in navigator);
    },
    geoCenter() {
      return this.geo.center;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  mounted() {
    this.geo.center = null;
    this.geo.gettingLocation = false;
    if (!this.geoLocationAvailable) {
      this.geo.err = true;
    }
  },
  methods: {
    onMapSelect(id) {
      this.name = null;
      this.city = null;
      this.$refs.searchGeoDd.hide();
      this.onSelect(this.items[id], false);
    },
    onMapRefresh(latlng) {
      this.geo.center = latlng;
      this.vicinityFetch(this.geo.center);
    },
    mapClose() {
      this.geo.gettingLocation = false;
      this.geo.center = null;
    },
    async mapSearch() {
      if (this.geo.center === null) {
        this.geo.gettingLocation = true;
        // event.preventDefault();
        navigator.geolocation.getCurrentPosition(async pos => {
          this.geo.gettingLocation = false;
          this.geo.center = [pos.coords.latitude.toFixed(5), pos.coords.longitude.toFixed(5)];
          try {
            await this.vicinityFetch(this.geo.center);
            // this.$refs.searchGeoDd.show();
          } catch(_) {
            this.$refs.searchGeoDd.hide();
            this.mapClose();
          }
        }, err => {
          this.geo.err = true;
          this.$refs.searchGeoDd.hide();
          this.mapClose();
          this.$store.commit('showWarn', err.message);
        });
      }
    },
    onSearch(event) {
      if (this.items.length) {
        // coming from GET promise
        return;
      }
      event.preventDefault();

      let qArray = [];
      if (this.name !== null && this.name !== '') {
        qArray.push(['name', 's', this.name]);
      }
      if (this.city !== null && this.city !== '') {
        qArray.push(['city', 's', this.city]);
      }
      if (qArray == []) {
          this.$emit('showWarn', this.warnText);
          return;
      }
      let qObj = Query.setFilter({ p: '10' }, qArray);
      REST.get('/restaurants', { params: qObj }
          ).then(resp => {
            this.items = resp.d.data;
            this.$refs.btn.show();
          }).catch(e => {
            if (e.code != 404) {
              this.$emit('showWarn', e.message);
            } else {
              this.name = null;
              this.city = null;
            }
          })
    },
    async vicinityFetch(arr) {
      if (arr && arr.length === 2) {
        REST.get('/restaurants/35000/' + arr[0] + '/' + arr[1]
          ).then(resp => {
            this.items = resp.d.data;
          }).catch(e => {
            this.$emit('showWarn', e.message);
          })
      }
    },
    onHide() {
      // this.name = null;
      // this.city = null;
      this.items = [];
    },
    onSelect(item, hideBtn) {
      let obj = {customerUuid4: item.uuid4, name: item.name, city: item.city, locationCurrency: item.locationCurrency, resAmount: item.resAmount};
      this.$emit('action', obj);
      if (hideBtn) {
        this.$refs.btn.hide();
      }
    },
  },
  props: {
    mapText: {
      type: String,
      default: null,
    },
    warnText: {
      type: String,
      default: 'Name or city required.',
    },
    labelText: {
      type: String,
      default: 'Enter Restaurant, or City',
    },
  },
  components: {
    MapCore,
    Icon,
  },
}
</script>

<style scoped>
/* .zindex {
  z-index: 1200;
} */
.mapwidth {
  width:97vw;
}
@media screen and (min-width: 800px) {
  .mapwidth {
    min-width:600px;
    width:600px;
  }
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
</style>
