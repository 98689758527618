<template>
  <l-map ref="map" :zoom="defzoom" :center="center" @contextmenu="onRefresh" class="mapheight">
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker :lat-lng="center">
      <l-icon :icon-url="require(`@/assets/markergreen.svg`)" />
      <l-popup><h6>{{ $t('dial.mapyou') }}</h6></l-popup>
    </l-marker>
    <l-marker v-for="(rest, index) in items" :key="index" :lat-lng="[rest.geoLat, rest.geoLong]" @contextmenu="$emit('clicked',index)">
      <l-icon :icon-url="ico" />
      <l-popup>
        <MapPopup :item="rest" :index="index" :btnText="btnText" @clicked="$emit('clicked',index)" />
      </l-popup>
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet/dist/vue2-leaflet.min.js';
// import { LMap, LMarker, LPopup, LIcon } from 'vue2-leaflet';
// import { default as LMap } from 'vue2-leaflet/src/components/LMap';
// import { default as LTileLayer } from 'vue2-leaflet/src/components/LTileLayer';
// import { default as LMarker } from 'vue2-leaflet/src/components/LMarker';
// import { default as LPopup } from 'vue2-leaflet/src/components/LPopup';
// import { default as LIcon } from 'vue2-leaflet/src/components/LIcon';
import MapPopup from '@/components/MapPopup';

export default {
  data() {
    return {
      defzoom:   14,
      url:      'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      ico: null,
    }
  },
  mounted() {
    this.$refs.map.mapObject.invalidateSize();
    this.ico = require('@/assets/markerblue.svg'); // "marker-icon.png" and "marker-shawdow.png" are loaded somewhere, but not found
  },
  methods: {
    onRefresh(event) {
      this.$emit('refreshed', [event.latlng.lat, event.latlng.lng]);
    },
  },
  props: {
    center: {
      type: Array,
      default() {
        return [52.515220, 13.419482];
      }
    },
    items: {
      type: Array,
      default() { return []; },
    },
    btnText: {
      type: String,
      default: null
    },
  },
  components: {
    MapPopup,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
  },
}
</script>

<style scoped>
.mapheight {
  /* width: 66vw; */
  height: 66vh;
}
</style>